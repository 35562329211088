import Cookies from "universal-cookie";
import styles from "./DemoExpired.module.scss";
import store from "../state/store";


export const DemoExpired = () => {
  const cookies = new Cookies();
  const licenseInfo = store.getState().system.lizenz;

  cookies.remove('token');

  let headingText, linkAreaText;
  if (licenseInfo?.lizenzumfang === 'Voll' && !licenseInfo.lizenzAktiv){
    headingText = "Lizenz ist abgelaufen";
    linkAreaText = "Kontaktiere uns, um erneut Zugang zur M365 Kaffeepause zu erhalten."
  } else if(licenseInfo?.lizenzumfang === 'Demo'&& !licenseInfo.lizenzAktiv){
     headingText = "Demo ist abgelaufen";
     linkAreaText = "Kontaktiere uns, um Zugang zur M365 Kaffeepause zu erhalten.";
  } 

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <div className={styles.formContainer}>
          <div className={styles.dotDecoUpper}></div>
          <div className={styles.content}>
            <div className={styles.cuppy}></div>
            <div>
              <div className={styles.heading}>{headingText}</div>
              <div className={styles.linkArea}>
                {linkAreaText}
                {" "}
                Unsere Preismodelle findest du{" "}
                <a
                  href="https://m365kaffeepause.de/microsoft-365-schulungen-kurse-trainings-preise/"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  hier
                </a>
                .
              </div>
            </div>

            <a
              href="https://m365kaffeepause.de/microsoft-365-schulungen-kurse-trainings-kontakt/"
              className={[styles.button, styles.link].join(" ")}
              target="_blank"
              rel="noreferrer"
            >
              Kontaktiere uns
            </a>
            <a
              href="https://rebrand.ly/M365-Kaffeepause-Feedback"
              className={styles.button}
              id={styles.buttonTwo}
              target="_blank"
              rel="noreferrer"
            >
              Gib uns Feedback
            </a>
          </div>
          <div className={styles.dotDecoLower}></div>
        </div>
      </div>
    </div>
  );
};