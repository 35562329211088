import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import styles from "./Login.module.scss";
import {AuthenticationService} from "../services/AuthenticationService";
import {useDispatch} from "react-redux";
import {setLizenz} from "../state/systemSlice";
import Cookies from "universal-cookie";

export const Login = () => {
  const dispatch = useDispatch();

  const [accessCode, setAccessCode] = useState("");
  const [rememberLogin, setRememberLogin] = useState(true);
  const [accessCodeInvalid, setAccessCodeInvalid] = useState(false);
  const [accessCodeInvalidReason, setAccessCodeInvalidReason] = useState("");

  const authService = new AuthenticationService();
  const cookies = new Cookies();

  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const param1 = urlParams.get("key");

  useEffect(() => {
    if (param1) {
      setAccessCode(param1);
    }
  }, [param1]);

  useEffect(() => {
    if (accessCode === param1) {
      const form = document.getElementById("form1") as HTMLFormElement;
      const syntheticEvent = {
        preventDefault: () => {},
      };
      if (form) {
        handleSubmit(syntheticEvent);
      }
    }
  });

  const handleSubmit = (event: {preventDefault: () => void}) => {
    event.preventDefault();
    const accessCodePattern = /^[A-Z0-9]{3}-[A-Z0-9]{3}-[A-Z0-9]{3}$/;
    if (accessCodePattern.test(accessCode)) {
      authService.getLizenzByAccessCode(accessCode).then((response) => {
        if (response !== null) {
          dispatch(setLizenz(response));
          if (!response.lizenzAktiv) {
            return navigate(`/expired`);
          } else {
            if (!rememberLogin) {
              cookies.set("token", response.token, {
                path: "/",
                secure: true,
                sameSite: "none",
              });
            }
            if (rememberLogin) {
              cookies.set("token", response.token, {
                path: "/",
                expires: new Date(
                  new Date().setFullYear(new Date().getFullYear() + 1)
                ),
                secure: true,
                sameSite: "none",
              });
            }
            return navigate("/");
          }
        } else {
          setAccessCodeInvalid(true);
          setAccessCodeInvalidReason(
            "Falscher Zugangscode. Bitte überprüfe den Zugangscode und probiere es erneut!"
          );
        }
      });
    } else {
      setAccessCodeInvalid(true);
      setAccessCodeInvalidReason(
        "Falscher Zugangscode. Der Zugangscode muss das Format XXX-XXX-XXX haben."
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <div className={styles.formContainer}>
          <div className={styles.dotDecoUpper}></div>
          <div className={styles.logoHeader}></div>
          <div>
            <div className={styles.welcomeArea}>
              <div className={styles.heading}>Willkommen!</div>
              <div>Melde dich mit deinem Zugangscode an.</div>
            </div>
            <form id="form1" onSubmit={handleSubmit}>
              <div className={styles.inputArea}>
                <input
                  className={[
                    styles.inputField,
                    accessCodeInvalid ? styles.invalid : null,
                  ].join(" ")}
                  type="text"
                  value={accessCode}
                  onChange={(e) => {
                    setAccessCode(e.target.value);
                    setAccessCodeInvalid(false);
                    setAccessCodeInvalidReason("");
                  }}
                  placeholder="Gib deinen 9-stelligen Zugangscode ein"
                />
                <div className={styles.error}>{accessCodeInvalidReason}</div>
              </div>
              <div className={styles.checkboxArea}>
                <label className={styles.checkbox} htmlFor="rememberLogin">
                  <div className={styles.checkboxLabel}>
                    Zugangscode speichern
                  </div>
                  <input
                    type="checkbox"
                    id="rememberLogin"
                    name="rememberLogin"
                    value="rememberLogin"
                    onChange={(e) => setRememberLogin(e.target.checked)}
                    defaultChecked
                  />
                  <span className={styles.checkmark}></span>
                </label>
              </div>
              <input
                className={styles.button}
                type="submit"
                value="Bestätigen"
              />
            </form>
          </div>
          <div className={styles.linkArea}>
            Hast du Probleme mit dem Zugangscode?{" "}
            <a
              href="https://m365kaffeepause.de/microsoft-365-schulungen-kurse-trainings-kontakt/"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              Kontaktiere uns
            </a>
          </div>
          <div className={styles.dotDecoLower}></div>
        </div>
      </div>
    </div>
  );
};
