import {ILernpfad} from "../../interfaces/ILernpfad";
import styles from "./LearnpathCard.module.scss";
import {Nugget} from "../atoms/Nugget";

export interface Props {
  learnpathMeta: ILernpfad;
  onClick: (lernpath: ILernpfad) => void;
}
export const LearnpathCard = (props: Props) => {
  return (
    <div className={styles.card} onClick={() => props.onClick(props.learnpathMeta)}>
      <div className={styles.imageContainer}>
        <img
          src={props.learnpathMeta.vorschaubildUrl}
          alt="coffee cup"
          className={styles.image}
        ></img>
      </div>
      <div className={styles.infoSection}>
        <div className={styles.title}>{props.learnpathMeta.titel}</div>
        <div className={styles.nugget}>
          <Nugget
            numOfContents={props.learnpathMeta.anzahlInhalte}
            coffeeSize={props.learnpathMeta.groesse}
          ></Nugget>
        </div>
      </div>
    </div>
  );
};
