import React from "react";
import ReactDOM from "react-dom/client";
import Cookies from 'universal-cookie';
import { Provider } from "react-redux";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppConfigService } from "./services/AppConfigService";
import store from "./state/store";
import { AuthenticationService } from "./services/AuthenticationService";

import "./index.css";
import "@fontsource/space-grotesk"; // Defaults to weight 400
import "@fontsource/space-grotesk/700.css"; // Specify weight
import "@fontsource/mulish"; // Defaults to weight 400
import "@fontsource/mulish/600.css"; // Specify weight
import "@fontsource/mulish/800.css";
import "@fontsource/mulish/700.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const cookies = new Cookies();

AppConfigService.instance.getConfig().then((appConfig) => {
  AuthenticationService.instance.getLizenzFromBaseUrlByToken(appConfig.apiUrl, cookies.get('token')).then((lizenz) => {
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <App appConfig={appConfig} lizenz={lizenz} />
        </Provider>
      </React.StrictMode>
    );
  });
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
