import styles from "./ContextMenuButton.module.scss";
import cup from "../../assets/menu-mug.svg";
import {ContextMenuContent} from "./ContextMenuContent";
import {useState} from "react";
import OutsideClickHandler from "react-outside-click-handler";

export const ContextMenuButton = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = (e: any) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };
  return (
    <OutsideClickHandler onOutsideClick={() => setMenuOpen(false)}>
      <div className={styles.button} onClick={toggleMenu}>
        <span className={styles.text}>Menu</span>
        <img
          src={cup}
          alt="happy smiling coffee cup with little arms and legs"
          className={styles.image}
        ></img>
        {menuOpen ? (
          <div>
            <ContextMenuContent></ContextMenuContent>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </OutsideClickHandler>
  );
};
