import {useNavigate} from "react-router-dom";

import {useEffect, useState} from "react";
import styles from "./Overview.module.scss";
import {LearnpathCard} from "../components/molecules/LearnpathCard";

import {ILernpfad} from "../interfaces/ILernpfad";
import {LernpfadService} from "../services/LernpfadService";

export const Overview = () => {
  const navigate = useNavigate();
  const [lernpfade, setLernpfade] = useState<ILernpfad[]>([]);

  useEffect(() => {
    const learnpathService = new LernpfadService();
    learnpathService.getLernpfade().then((lernpfadeResult) => {
      if(lernpfadeResult) {
        setLernpfade(lernpfadeResult);
      }
    });
  }, []);

  const handleLernpfadClick = (lernpfad: ILernpfad) => {
    navigate(`/lernpfad/${lernpfad.id}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        <div className={styles.heading}>Was möchtest du lernen?</div>
      </div>
      <div className={styles.cardArea}>
        {lernpfade.map((lernpfad) => (
          <LearnpathCard
            learnpathMeta={lernpfad}
            onClick={handleLernpfadClick}
            key={lernpfad.id}
          ></LearnpathCard>
        ))}
      </div>
    </div>
  );
};
