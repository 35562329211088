import { ReactNode } from "react";
import store from "../../state/store";
import Watermark from '@uiw/react-watermark';
import WatermarkIcon from '../../assets/Watermark.png';

export interface IDemoWatermarkProps {
    children?: ReactNode;
    active: boolean;
}
  
export const DemoWatermark = (props: IDemoWatermarkProps) => {
    const lizenz = store.getState().system.lizenz;

    if(lizenz?.lizenzumfang !== 'Demo' || !props.active) {
        return (
            <div>{props.children}</div>
        );
    } else {
        return (
            <Watermark
                image={WatermarkIcon}
                rotate={0}
                width={2918}
                height={545}
                
                markStyle={{backgroundRepeat: 'no-repeat', backgroundPositionX: 'right', backgroundPositionY: 'bottom', backgroundSize: '50%', height: '100%'}}
            >{props.children}</Watermark>
        )
    }
};