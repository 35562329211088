import { configureStore } from '@reduxjs/toolkit';
import systemReducer, { SystemState } from './systemSlice';
import lernpfadReducer, { LernpfadState} from './lernpfadSlice';

const store = configureStore({
    reducer: {
        system: systemReducer,
        lernpfad: lernpfadReducer
    }
});

export default store;

export type RootState = {
    system: SystemState;
    lernpfad: LernpfadState;
};
