import {ILerninhalt} from "../../interfaces/ILerninhalt";
import {ILernpfad} from "../../interfaces/ILernpfad";
import styles from "./LearningpathMenu.module.scss";
import Dropdown from "../../assets/Dropdown_down.svg";
import Video from "../../assets/Video.svg";
import Infografik from "../../assets/Infopgrahic.svg";
import SchrittFürSchritt from "../../assets/Schritt.svg";
import Checkliste from "../../assets/Checklist.svg";
import Quiz from "../../assets/Quizz.svg";
import FAQ from "../../assets/FAQ.svg";
import Link from "../../assets/External-link.svg";
import Intro from "../../assets/Uebersicht_eye2.svg";
import React, {useState} from "react";
import {useKeys} from "../../hooks";
import {useSelector} from "react-redux";

export interface Props{
  lernpfad: ILernpfad;
  onClick: (lerninhalt: ILerninhalt) => void;
}

// export interface DropdownProps{
//   options: ILerninhalt[];
//   onSelect: (lerninhalt: ILerninhalt) => void;
// }

// const Dropdown: React.FC<DropdownProps> = ({ options, onSelect }) => {
//   const [selected, setSelected] = useState(options[0]?.id);

//   const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedId = event.target.value;
//     const selectedOption = options.find((option) => option.id === selectedId);
//     if (selectedOption) {
//       setSelected(selectedId);
//       onSelect(selectedOption);
//     }
//   };

//   return (
//     <select value={selected} onChange={handleSelect}>
//       {options.map((option) => (
//         <option key={option.id} value={option.id}>
//           {option.name} - {option.typ} - Position: {option.position}
//         </option>
//       ))}
//     </select>
//   );
// };

export const LearningpathMenu = (props: Props) => {
  const [selected, setSelected] = useState(props.lernpfad.lerninhalte[0]?.id);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [completedLerninhalt, setCompletedLerninhalt] = useState<string[]>([]);
  const fullScreen = useSelector((state:any) => state.system.fullScreen);
  const [filteredPosition, setFilteredPosition] = useState<ILerninhalt[]>(props.lernpfad.lerninhalte.filter((item) => !item.position.toString().includes('.')));

  const onClick = (lerninhalt: ILerninhalt) => {
    
    if (!lerninhalt.position.toString().includes('.')) {
      const clickedPosition = lerninhalt.position.toString();
      const updatedPositions = props.lernpfad.lerninhalte.filter((item) => {
        if (item.position.toString().startsWith(clickedPosition) && !filteredPosition.includes(item)) {
          return true;
        }
        return !item.position.toString().includes('.');
      });
      setFilteredPosition(updatedPositions);
    }
    
    setSelected(lerninhalt.id);
    setCompletedLerninhalt([...completedLerninhalt, lerninhalt.id]);

    props.onClick(lerninhalt);

  };

  useKeys('ArrowUp', () => {
    // don't allow arrow keys when in full screen till a feature to allow full screen for
    // all types
    if (!fullScreen && selectedIndex > 0) {
      setSelectedIndex((prevState) => prevState - 1);
      setSelected(props.lernpfad.lerninhalte[selectedIndex - 1]?.id);
      props.onClick(props.lernpfad.lerninhalte[selectedIndex - 1]);
    }
  });

  useKeys('ArrowDown', () => {
    // don't allow arrow keys when in full screen till a feature to allow full screen for
    // all types
    if (!fullScreen && selectedIndex < props.lernpfad.lerninhalte?.length - 1) {
      setSelectedIndex((prevState) => prevState + 1);
      setSelected(props.lernpfad.lerninhalte[selectedIndex + 1]?.id);
      props.onClick(props.lernpfad.lerninhalte[selectedIndex + 1]);
    }
  });

  return (
    <div className={styles.menuContainer}>
      
      {filteredPosition
        .sort((l1, l2) => (l1.position ?? 0) - (l2.position ?? 0))
        .map((lerninhalt: ILerninhalt) => {
          let icon;
          switch (lerninhalt.typ) {
            case "Video": {
              icon = Video;
              break;
            }
            case "Checkliste": {
              icon = Checkliste;
              break;
            }
            case "FAQ": {
              icon = FAQ;
              break;
            }
            case "Intro": {
              icon = Intro;
              break;
            }
            case "Infografik": {
              icon = Infografik;
              break;
            }
            case "Link": {
              icon = Link;
              break;
            }
            case "Schritt-für-Schritt-Anleitung": {
              icon = SchrittFürSchritt;
              break;
            }
            case "Quiz": {
              icon = Quiz;
              break;
            }

            default: {
              icon = Checkliste;
              break;
            }
          }
          let drop;
          let rotate;
          if (
            props.lernpfad.lerninhalte.some(
              (item) =>
                item.position.toString().startsWith(lerninhalt.position.toString() + '.') &&
                item.position.toString() !== lerninhalt.position.toString() &&
                !filteredPosition.includes(item)
            )
          ) {
            drop = Dropdown;
            rotate = 0;
          }
          if(
            props.lernpfad.lerninhalte.some(
              (item) =>
                item.position.toString().startsWith(lerninhalt.position.toString() + '.') &&
                item.position.toString() !== lerninhalt.position.toString() &&
                filteredPosition.includes(item)
            )
          ) {
            drop = Dropdown;
            rotate = 180;
          }

          return (
      <div
              key={lerninhalt.id}
              onClick={() => onClick(lerninhalt)}
              className={`${
                selected === lerninhalt.id ? styles.selected : ""
              } ${styles.menuItem}`}
          >
              <div className={styles.statusInfo}>
                <span>{lerninhalt.position}</span>
              </div>

            <div>
              <div className={styles.menuItemName}>{lerninhalt.name}</div>
                <div className={styles.menuItemType}>
                  { !drop && (<img
                    src={icon}
                    alt={lerninhalt.typ}
                    className={styles.icon}
                  ></img>
                  )}
                  <div>{ !drop && (
                    lerninhalt.typ
                  )}
                  </div>
                </div>
              </div>
              <div >
              { drop && (<img
                  src={drop}
                  alt="dropdown-icon"
                  className={styles.dropDown}
                  style={{transform: `rotate(${rotate}deg)`}}
                ></img>)}
              </div>
            </div>
          );
        })}
    </div>
  );
};
