import axios from "axios";
import {ILernpfad} from "../interfaces/ILernpfad";
import store from "../state/store";
import { setLizenz } from "../state/systemSlice";

export class LernpfadService {
  public getLernpfade(): Promise<ILernpfad[]> {
    return this.get(`/lernpfade`).then((lernpfade: ILernpfad[]) => {
      lernpfade.sort((l1,l2) => {
        if(l1.position === l2.position) return l1.titel?.localeCompare(l2.titel);
        return l1.position - l2.position;
      });

      return lernpfade;
    });
  }

  public getLernpfad(id: string): Promise<ILernpfad> {
    return this.get(`/lernpfade/${id}`).then((lernpfad: ILernpfad) => {
      lernpfad.lerninhalte.sort((l1,l2) => {
        if(l1.position === l2.position) return l1.name?.localeCompare(l2.name);
        return l1.position - l2.position;
      });
      lernpfad.lerninhalte.forEach(lerninhalt => {
        lerninhalt.assets.sort((a1,a2) => {
          if(a1.position === a2.position) return a1.name?.localeCompare(a2.name);
          return a1.position - a2.position;
        });
      });

      return lernpfad;
    });
  }

  private get(url: string): Promise<any> {
    const token = store.getState().system.lizenz?.token;
    const baseUrl = store.getState().system.apiUrl;

    const data = axios
      .get(`${baseUrl}${url}`, {
        headers: {
          "x-token": token,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if(error?.response?.data === 'licenseExpired') {
          const lizenz = store.getState().system.lizenz;
          store.dispatch(setLizenz({...lizenz, lizenzAktiv: false}));
        } else {
          console.error(error);
          // TODO: error page or something
        }
      });
    return data;
  }
}
