import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

import {LernpfadService} from "../services/LernpfadService";

import styles from "./Lernpfad.module.scss";
import {ILernpfad} from "../interfaces/ILernpfad";
import {ILerninhalt} from "../interfaces/ILerninhalt";
import {LernpfadHeader} from "../components/molecules/LernpfadHeader";
import {LerninhaltViewer} from "../components/molecules/LerninhaltViewer";
import {LearningpathMenu} from "../components/molecules/LearningpathMenu";

export const Lernpfad = () => {
  const {id} = useParams();
  const [lernpfad, setLernpfad] = useState<ILernpfad>();
  const [lerninhalt, setLerninhalt] = useState<ILerninhalt>();
  const navigate = useNavigate();

  useEffect(() => {
    const lernpfadService = new LernpfadService();
    lernpfadService.getLernpfad('' + id).then((lernpfadResponse) => {
      if (lernpfadResponse) {
        setLernpfad(lernpfadResponse);
        setLerninhalt(lernpfadResponse.lerninhalte[0]);
      } else {
        return navigate(`/`);
      }
    });
  }, [id]);

  const onBackClick = () => {
    navigate("/");
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <LernpfadHeader lernpfad={lernpfad} onBackClick={onBackClick}/>
        <div className={styles.content}>
          <div className={styles.lerninhaltColumn}>
            <LerninhaltViewer lerninhalt={lerninhalt}></LerninhaltViewer>
          </div>
          <div className={styles.naviColumn}>
            <div className={styles.menu}>
              {lernpfad && (
                <LearningpathMenu
                  lernpfad={lernpfad}
                  onClick={setLerninhalt}
                ></LearningpathMenu>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
