import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILizenz } from '../interfaces/ILizenz';

export interface SystemState {
  apiUrl: string;
  lizenz: ILizenz|null;
  fullScreen: boolean;
}

const initialState: SystemState = {
  apiUrl: '',
  lizenz: {
    lizenzAktiv: false,
    token: '',
    lizenzumfang: 'Demo'
  },
  fullScreen: false
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setApiUrl(state, action: PayloadAction<string>) {
      state.apiUrl = action.payload;
       //state.apiUrl = 'http://localhost:7267/api/v1';
    },
    setLizenz(state, action: PayloadAction<ILizenz|null>) {
      state.lizenz = action.payload
    },
    setFullScreen(state, action: PayloadAction<boolean>) {
      state.fullScreen = action.payload;
    }
  }
})

export const { setApiUrl, setLizenz, setFullScreen } = systemSlice.actions;
export default systemSlice.reducer;