import styles from "./ContextMenuContent.module.scss";
import Link from "../../assets/External-link.svg";

export const ContextMenuContent = () => {
  const menuItems = [
    {
      title: "Feedback geben",
      link: "https://rebrand.ly/M365-Kaffeepause-Feedback",
    },
    {
      title: "Kontakt",
      link: "https://m365kaffeepause.de/microsoft-365-schulungen-kurse-trainings-kontakt/",
    },
    {
      title: "Preise",
      link: "https://m365kaffeepause.de/microsoft-365-schulungen-kurse-trainings-preise/",
    },
    {
      title: "Übersicht Lernpakete",
      link: "https://m365kaffeepause.de/microsoft-365-schulungen-kurse-trainings-lernpakete-inhalte/",
    },
    {
      title: "Datenschutz",
      link: "https://m365kaffeepause.de/datenschutz/",
    },
    {
      title: "AGB",
      link: "https://m365kaffeepause.de/agb/",
    },
    {
      title: "Impressum",
      link: "https://m365kaffeepause.de/impressum-haftungsausschluss/",
    },
  ];
  return (
    <div className={styles.container}>
      {menuItems.map((item, index) => (
        <a
          href={item.link}
          target="_blank"
          rel="noreferrer"
          className={styles.menuItem}
          key={index}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img src={Link} alt="Externer Link" className={styles.icon} />
          <span>{item.title}</span>
        </a>
      ))}
    </div>
  );
};
