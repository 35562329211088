import styles from "./Nugget.module.scss";
import smallCup from "../../assets/small-cup.svg";
import mediumCup from "../../assets/medium-cup.svg";
import bigCup from "../../assets/big-cup.svg";

export interface Props {
  numOfContents: number;
  coffeeSize: string;
}

export const Nugget = (props: Props) => {
  let cup;

  switch (props.coffeeSize) {
    case "kurz": {
      cup = smallCup;
      break;
    }
    case "mittel": {
      cup = mediumCup;
      break;
    }
    default: {
      cup = bigCup;
      break;
    }
  }

  return (
    <div className={styles.nugget}>
      <div>{props.numOfContents} Inhalte</div>
      <div className={styles.imageContainer}>
        <img src={cup} alt="coffee cup" className={styles.image}></img>
      </div>
    </div>
  );
};
